@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900|Montserrat:900);
:root {
    --primary-color: rgb(45, 201, 150);
    --primary-color-light: rgb(25, 141, 110);
}

* {
    box-sizing: border-box;
}

html {
    color: rgba(255, 255, 255, 0.9);
    font: 100%/1.75 Morriweather, Georgia, serif;
    background-color: black
}

body {
    -webkit-font-kerning: normal;
            font-kerning: normal;
    font-family: 'Merriweather', 'Georgia', serif;
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt", "kern";
            font-feature-settings: "kern", "liga", "clig", "calt", "kern";
    font-weight: 400;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
}

pre,
code {
    font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace;
    font-size: 0.85rem;
    line-height: 1.5;
}

pre {
    border-radius: 10px;
    overflow: auto;
    padding: 1em;
}

a {
    color: rgb(45, 201, 150);
    color: var(--primary-color);
}

a:hover {
    text-decoration: none;
}

p,
pre,
ul,
ol {
    margin-top: 0;
    margin-bottom: 1.75rem;
}
.ArticleMeta_tags__6oYN2,
.ArticleMeta_tags__6oYN2 li {
  display: inline;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ArticleMeta_tags__6oYN2 li:not(:first-child)::before {
  content: ', ';
}
.ArticleSummary_ArticleSummary__2NREv h2 {
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 0;
}

.ArticleSummary_ArticleSummary__2NREv a {
  text-decoration: none;
}
.Bio_Bio__3aLWi {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 4.375rem;
}
  
.Bio_Bio__3aLWi img {
  border-radius: 50%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 3.5rem;
  margin-bottom: 0;
  margin-right: 0.875rem;
  width: 3.5rem;
}
.Pagination_Pagination__25Aer {
  display: block;
  margin: 4.375rem 0 7rem;
}
  
.Pagination_Pagination__25Aer .Pagination_previous__3OfpW {
  margin-right: 0.5rem;
}
.Pagination_Pagination__25Aer .Pagination_next__QpFFK {
  margin-left: 0.5rem;
}
.BlogIndexPage_title__3AxQk {
    font-family: Montserrat, sans-serif;
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 3.5rem;
    margin-top: 0;
    margin-bottom: 2.625rem;
    text-rendering: optimizeLegibility;
}

.BlogIndexPage_title__3AxQk a {
    color: white;
    text-decoration: none;
}

.BlogIndexPage_articlesList__235Wt,
.BlogIndexPage_articlesList__235Wt>li {
    padding: 0;
    margin: 0 0 2.625rem;

    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    -webkit-align-items: stretch;
            align-items: stretch;
}

.BlogIndexPage_footer__31nm3 {
    margin-top: 7rem;
    margin-bottom: 0.5rem;
}

.LoadingIndicator_LoadingIndicator__pMtFo {
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color-light);
  background-size: 35px 35px;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  transition: opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition-delay: 0;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  opacity: 0;
}

.LoadingIndicator_LoadingIndicator__pMtFo.LoadingIndicator_active__RuleZ {
  opacity: 1;
  transition-delay: 333ms;
  -webkit-animation: LoadingIndicator_LoadingIndicator-animation__2NeC9 10s ease-out;
          animation: LoadingIndicator_LoadingIndicator-animation__2NeC9 10s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes LoadingIndicator_LoadingIndicator-animation__2NeC9 {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes LoadingIndicator_LoadingIndicator-animation__2NeC9 {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
.BlogLayout_container__1TFYJ {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}

.BlogLayout_title__345vN {
  font-family: Montserrat, sans-serif;
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}
.BlogLayout_title__345vN > a {
  color: var(--primary-color-light);
  text-decoration: none;
}

.BlogPostLayout_title__1novH {
  font-family: Montserrat, sans-serif;
  font-weight: 900;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
.BlogPostLayout_title__1novH > a {
  text-decoration: none;
}

.BlogPostLayout_header__3MZay {
  margin-bottom: 2.4rem;  
}
.BlogPostLayout_header__3MZay .BlogPostLayout_title__1novH {
  font-size: 2.5rem;
  margin-top: 1.7rem;
  margin-bottom: 0;
}
.BlogPostLayout_header__3MZay .BlogPostLayout_title__1novH > a {
  color: white;
}

.BlogPostLayout_footer__2guMv {
  margin-top: 7rem;
}
.BlogPostLayout_footer__2guMv .BlogPostLayout_title__1novH {
  font-size: 1.44rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.BlogPostLayout_footer__2guMv .BlogPostLayout_title__1novH > a {
  color: var(--primary-color-light);
}

.BlogPostLayout_bio__2Q1cm {
  margin-bottom: 7rem;
}

.BlogPostLayout_links__2GLmJ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.BlogPostLayout_links__2GLmJ .BlogPostLayout_next__Tdnxh {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  text-align: right;
}

.BlogPostLayout_content__3jgVf hr {
  background: hsla(0,0%,0%,0.2);
  border: none;
  height: 1px;
}

.BlogPostLayout_content__3jgVf h2,
.BlogPostLayout_content__3jgVf h3,
.BlogPostLayout_content__3jgVf h4,
.BlogPostLayout_content__3jgVf h5,
.BlogPostLayout_content__3jgVf h6 {
  font-weight: 900;
  line-height: 1.1;
  margin-top: 3.5rem;
  text-rendering: optimizeLegibility;
}
  
/**
* Based on copypasta from Remy Bach and Sarah Drasner
*/
.BlogPostLayout_content__3jgVf code,
.BlogPostLayout_content__3jgVf pre {
  color: white;
  font-family: Consolas,Menlo,Monaco,source-code-pro,Courier New,monospace;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
       tab-size: 4;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
  
/* Code blocks */
.BlogPostLayout_content__3jgVf pre {
  overflow: auto;
  padding: 1em;
}

.BlogPostLayout_content__3jgVf :not(pre) > code,
.BlogPostLayout_content__3jgVf pre {
  background: hsla(0,0%,0%,0.9);
}
  
.BlogPostLayout_content__3jgVf pre::-moz-selection,
.BlogPostLayout_content__3jgVf pre::-moz-selection {
  background: hsl(207, 4%, 16%);
}
  
.BlogPostLayout_content__3jgVf pre::-moz-selection,
.BlogPostLayout_content__3jgVf pre::selection {
  background: hsl(207, 4%, 16%);
}
  
/* Text Selection colour */
.BlogPostLayout_content__3jgVf pre::-moz-selection,
.BlogPostLayout_content__3jgVf pre ::-moz-selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

.BlogPostLayout_content__3jgVf pre::selection,
.BlogPostLayout_content__3jgVf pre ::selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

/* Inline code */
.BlogPostLayout_content__3jgVf :not(pre) > code {
  border-radius: .3em;
  background: rgba(0,26,155,0.2);
  color: rgb(229, 229, 229); 
  padding: .15em .2em .05em;
  white-space: normal;
}
.BlogPostLayout_content__3jgVf a > code {
  color: var(--primary-color);
}

.BlogPostLayout_content__3jgVf .highlighted-line {
  background-color: hsla(207, 95%, 15%, 1);
  display: block;
  margin-right: -1em;
  margin-left: -1em;
  padding-right: 1em;
  padding-left: 0.75em;
  border-left: 0.25em solid #ffa7c4;
}

.BlogPostLayout_content__3jgVf .token.attr-name {
  color: rgb(173, 219, 103);
  font-style: italic;
}

.BlogPostLayout_content__3jgVf .token.comment {
  color: rgb(99, 119, 119);
}

.BlogPostLayout_content__3jgVf .token.string,
.BlogPostLayout_content__3jgVf .token.url {
  color: rgb(173, 219, 103);
}

.BlogPostLayout_content__3jgVf .token.variable {
  color: rgb(214, 222, 235);
}

.BlogPostLayout_content__3jgVf .token.number {
  color: rgb(247, 140, 108);
}

.BlogPostLayout_content__3jgVf .token.builtin,
.BlogPostLayout_content__3jgVf .token.char,
.BlogPostLayout_content__3jgVf .token.constant,
.BlogPostLayout_content__3jgVf .token.function {
  color: rgb(130, 170, 255);
}

.BlogPostLayout_content__3jgVf .token.punctuation {
  color: rgb(199, 146, 234);
}

.BlogPostLayout_content__3jgVf .token.selector,
.BlogPostLayout_content__3jgVf .token.doctype {
  color: rgb(199, 146, 234);
  font-style: 'italic';
}

.BlogPostLayout_content__3jgVf .token.class-name {
  color: rgb(255, 203, 139);
}

.BlogPostLayout_content__3jgVf .token.tag,
.BlogPostLayout_content__3jgVf .token.operator,
.BlogPostLayout_content__3jgVf .token.keyword {
  color: #ffa7c4;
}

.BlogPostLayout_content__3jgVf .token.boolean {
  color: rgb(255, 88, 116);
}

.BlogPostLayout_content__3jgVf .token.property {
  color: rgb(128, 203, 196);
}

.BlogPostLayout_content__3jgVf .token.namespace {
  color: rgb(178, 204, 214);
}
