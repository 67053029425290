.title {
    font-family: Montserrat, sans-serif;
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 3.5rem;
    margin-top: 0;
    margin-bottom: 2.625rem;
    text-rendering: optimizeLegibility;
}

.title a {
    color: white;
    text-decoration: none;
}

.articlesList,
.articlesList>li {
    padding: 0;
    margin: 0 0 2.625rem;

    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
}

.footer {
    margin-top: 7rem;
    margin-bottom: 0.5rem;
}